
import { Component, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
import { mobileRulesEl } from "@/utils/verify";
import GeneralVehicleSelect from "@/components/scope/general-vehicle-select.vue";
import AreaSelect from "@/components/scope/area-select.vue";
import t from "@common/src/i18n/t";
const management = namespace("management");
const base = namespace("base");
const app = namespace("app");
const setting = namespace("setting");
const user = namespace("user");

@Component({
  components: { AreaSelect, GeneralVehicleSelect }
})
export default class managementEdit extends Vue {
  @management.Action saveTenant;
  @management.Action getTenantDetail;
  @base.Action getDictionaryList;
  @base.Action getVehicleInfoByVin;
  @app.Mutation setPBPX;
  @setting.Action getPcPermissionList;
  @setting.Action getRoleDetail;
  @setting.Action getFunctionList;
  treeProps = {
    label: "menuName"
  };
  get breadData() {
    return [
      { name: t("v210831.tenant-information"), path: "/management/tenant-list" },
      { name: t("v210831.edit-tenant-information") }
    ];
  }
  pcPermList: any = [];
  appPermList: any = [];
  standardPcPermList: any = [];
  standardAppPermList: any = [];
  tenantFormData = {
    tenantName: "",
    contactName: "",
    mobile: "",
    email: "",
    stateName: "",
    cityName: "",
    districtName: "",
    address1: "",
    appType: "1",
    status: 1,
    area: null
  };
  showMobileUsedTips: boolean = false;
  options: any[] = [];

  showAreaSelect: boolean = false; //控制地区信息set完成后，显示组件
  loadEnd: boolean = false;
  managementFormRules = {
    tenantName: [{ required: true, message: t("v210831.please-enter-tenant"), trigger: "blur" }],
    contactName: [{ required: true, message: t("v210831.please-enter-contact"), trigger: "blur" }],
    mobile: [
      { required: true, message: t("v210831.please-enter-the-linkman-mobile"), trigger: "blur" },
      { validator: this.validateMobile, trigger: "blur" }
    ]
  };
  created() {
    this.setPBPX(52);
    this.init();
  }
  validateMobile(rule, value, callback) {
    if (!value) {
      callback();
    } else {
      mobileRulesEl(value, callback);
    }
  }
  handleAreaChange(val) {
    this.$nextTick(() => {
      this.$nextTick(() => {
        const labels = (this.$refs["areaCascader"] as any).getLabels();
        this.tenantFormData.stateName = labels[0];
        this.tenantFormData.cityName = labels[1];
        this.tenantFormData.districtName = labels[2];
      });
    });
  }
  submitForm() {
    if (!this.tenantFormData.appType) {
      return this.$message.error(t("v210831.please-select-a-versions"));
    }
    (this.$refs["tenantForm"] as any).validate(valid => {
      console.log(valid);
      valid && this.editmanagement();
    });
  }
  async init() {
    this.getTenantDetail({
      tenantCode: this.$route.params.id
    }).then(data => {
      Object.assign(this.tenantFormData, data.data);
      this.tenantFormData.area = [data.data.stateName, data.data.cityName, data.data.districtName];
      this.showAreaSelect = true;
    });
    this.getPermission("1");
    this.getPermission("2");
  }
  checkKeys: any = [];
  async getPermission(type) {
    let permission = await this.getFunctionList({
      appType: type
    });
    if (type === "1") {
      this.pcPermList = this.viewFilterTree(permission.data.pcMenuList);
      this.appPermList = this.viewFilterTree(permission.data.appMenuList);
      // (this.$refs["pcTree"] as any).setCheckedKeys(this.checkKeys, false);
      // (this.$refs["appTree"] as any).setCheckedKeys(this.checkKeys, false);
    } else {
      this.standardPcPermList = this.viewFilterTree(permission.data.pcMenuList);
      this.standardAppPermList = this.viewFilterTree(permission.data.appMenuList);
    }
  }
  viewFilterTree(orginalTree) {
    const tree = orginalTree;
    let _this = this;
    let f = function(treeArr) {
      for (let index = 0; index < treeArr.length; index++) {
        const element = treeArr[index];
        _this.checkKeys.push(element.resourceCode);
        element.disabled = true;
        if (element.children) {
          f(element.children);
        }
      }
    };
    f(tree);
    return tree;
  }
  async editmanagement() {
    this.saveTenant(this.tenantFormData).then(data => {
      this.$message.success(t("base.success") as any);
      this.$router.push(`/management/tenant-list`);
    });
  }
}
